import * as React from 'react';
import {Component, MouseEvent, ReactNode} from 'react';
import {observer} from 'mobx-react';

import {Wave} from 'components/wave/wave.component';

import {MessageController} from 'components/message/message.controller';
import {BellController} from 'components/bell/bell.controller';

import {NotificationConsumer} from 'providers/notification/notification.consumer';
import {NotificationController} from 'providers/notification/notification.controller';

import {Notification} from 'services/notification/notification.model';
import dateFormat = require('dateformat');
import {ContentProps} from "components/chat/content/content.props";

@observer
export class Message extends Component {

    public controller: MessageController = new MessageController();
    public bController: BellController = new BellController();

    public componentWillMount = async (): Promise<void> => {
        await this.controller.init();
        await this.bController.init();
    };

    public render(): ReactNode {
        return (
            <div
                className={`notification-bell-container ${this.controller.active ? 'active' : (this.controller.notifications.length > 0 ? 'has-notifications' : '')}`}>
                <NotificationConsumer>
                    {(observer: NotificationController): ReactNode => {
                        this.controller.subscribe(observer);
                        return null;
                    }}
                </NotificationConsumer>
                {this.controller.loading ? <Wave/> : (
                    <div
                        className="notification-bell-icon"
                        onClick={async (event: MouseEvent<HTMLDivElement>): Promise<void> => {
                            event.preventDefault();
                            this.controller.active = !this.controller.active;
                            if(this.controller.active) {
                                this.bController.active = false;
                            }
                            await this.controller.readAll();
                        }}
                    >
                        <i className="fas fa-comment"/>
                        {this.controller.notifications.length > 0 ? <span
                            className="notification-bell-counter">{this.controller.notifications.length}</span> : ''}
                    </div>
                )}
                <div className="notification-bell-list-container">
                    {this.controller.notifications.length ? (
                        this.controller.notifications.slice(-5).map((notification: Notification): ReactNode => (
                            <div key={notification.id}
                                 className={`notification-bell-list-item with-image ${notification.read_at ? '' : 'unread'}`}>
                                <div className="notification-avatar">
                                    <img src={notification.data.sender.avatar_url} alt=""/>
                                </div>
                                <h4 dangerouslySetInnerHTML={{__html: notification.data.sender.name}}/>
                                <p dangerouslySetInnerHTML={{__html: notification.data ? notification.data.text : 'default text'}}/>
                                <div
                                    className="notification-meta">{dateFormat(new Date(notification.created_at), 'dd / mmm / yy - HH:MM')}</div>
                            </div>
                        ))
                    ) : (
                        <div className="notification-empty">No messages found!</div>
                    )}
                    <div className="notification-bell-list-footer">
                        <a href="/chat">Click here to read all messages.</a>
                    </div>
                </div>
            </div>
        );
    }

}