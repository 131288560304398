import * as React from 'react';
import { Component, DragEvent, ReactNode } from 'react';
import { observer } from 'mobx-react';

import { RoomController } from 'components/chat/room/room.controller';
import { RoomProps } from 'components/chat/room/room.props';

import { Content } from 'components/chat/content/content.component';
import { Error } from 'components/chat/error/error.component';
import { Input } from 'components/chat/input/input.component';
import { Spinner } from 'components/spinner/spinner.component';
import { Splash } from 'components/chat/splash/splash.component';
import { Member } from 'services/user/member.model';
import { Influencer } from 'services/user/influencer.model';
import { User } from 'services/user/user.model';
import { Label } from 'services/user/label.model';
import { Message } from 'interfaces/chat/message.interface';
import { NotificationController } from 'providers/notification/notification.controller';
import { NotificationConsumer } from 'providers/notification/notification.consumer';

@observer
export class Room extends Component<RoomProps> {

    public controller: RoomController = new RoomController();

    public componentWillMount = async (): Promise<void> => {
        await this.controller.init();
        await this.controller.open(this.props.member);
    };

    public componentWillUpdate = async (nextProps: RoomProps): Promise<void> => {
        let current: Member<any> = this.props.member;
        let next: Member<any> = nextProps.member;

        if (next === undefined) {
            this.controller.chat = null;
        }

        if (typeof current === 'object' && current !== null && typeof next === 'object' && next !== null) {

            if (current.type !== next.type) {
                return await this.controller.open(next);
            }

            switch (current.type) {
                case 'influencer':
                    if ((current.data as Influencer).user.id !== ((next.data as Influencer)).user.id) {
                        return await this.controller.open(next);
                    }
                    break;
                case 'user':
                    if ((current.data as User).id !== ((next.data as User)).id) {
                        return await this.controller.open(next);
                    }
                    break;
                case 'label':
                    if ((current.data as Label).user.id !== ((next.data as Label)).user.id) {
                        return await this.controller.open(next);
                    }
                    break;
                default:
                    return;
            }
        } else if (typeof next === 'object' && next !== null) {
            return await this.controller.open(next);
        }
    };

    public render(): ReactNode {
        return (
            <div
                className={`chat-message-room${this.controller.dragging ? ' dragging' : ''}`}
                onDragEnter={(event: DragEvent<HTMLDivElement>): void => {
                    event.preventDefault();
                    this.controller.dragging = true;
                }}
                onDragOver={(event: DragEvent<HTMLDivElement>): void => {
                    event.preventDefault();
                }}
                onDragLeave={(event: DragEvent<HTMLDivElement>): void => {
                    event.preventDefault();
                    this.controller.dragging = false;
                }}
                onDrop={async (event: DragEvent<HTMLDivElement>): Promise<void> => {
                    event.preventDefault();
                    this.controller.dragging = false;
                    this.controller.attachments = [...this.controller.attachments, ...Array.from(event.dataTransfer.files)];
                }}
            >
                <NotificationConsumer>
                    {(observer: NotificationController): ReactNode => {
                        this.controller.subscribe(observer);
                        return null;
                    }}
                </NotificationConsumer>
                {this.controller.loading ? <Spinner/> : this.controller.error ? <Error>{this.controller.error.message}</Error> : this.controller.chat ? (
                    <div className="chat-message-room-container">
                        <Content
                            user={this.controller.user}
                            filter={this.props.filter}
                            messages={this.controller.history}
                            onEdit={async (message: Message): Promise<void> => {
                                await this.controller.update(message);
                            }}
                            onDelete={async (message: Message): Promise<void> => {
                                await this.controller.destroy(message);
                            }}
                        />
                        <Input
                            message={this.controller.message}
                            attachments={this.controller.attachments}
                            onAttach={(files: File[]): void => {
                                this.controller.attachments = [...this.controller.attachments, ...files];
                            }}
                            onDetach={(file: File): void => {
                                this.controller.attachments = this.controller.attachments.filter((attachment: File): boolean => file !== attachment);
                            }}
                            onChange={(value: string): void => {
                                this.controller.message = value;
                            }}
                            onSend={async (): Promise<void> => {
                                await this.controller.send();
                            }}
                        />
                    </div>
                ) : <Splash>Select a chat in the list to start a conversation</Splash>}
            </div>
        );
    }

}