import '@babel/polyfill';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route , Switch} from 'react-router-dom';

import { Bell } from 'components/bell/bell.component';
import { Screen } from 'components/screen/screen.component';
import { Notifications} from 'components/notifications/notifications.component';
import {LanguageProvider } from 'providers/language/language.provider';
import { AuthenticationProvider } from 'providers/authentication/authentication.provider';
import { NotificationProvider } from 'providers/notification/notification.provider';import {Message} from "components/message/message.component";

window.addEventListener('DOMContentLoaded', (): void => {
    let app: HTMLElement = document.querySelector('#reactroot');
    let bell: NodeList = document.querySelectorAll('#notification-bell');

    if (app) {
        ReactDOM.render((
            <LanguageProvider>
                <AuthenticationProvider>
                    <NotificationProvider>
                        <BrowserRouter>
                            <Switch>
                                <Route path="/chat" exact component={Screen}/>
                                <Route path="/notifications" exact component={Notifications}/>
                            </Switch>
                        </BrowserRouter>
                    </NotificationProvider>
                </AuthenticationProvider>
            </LanguageProvider>
        ), app);
    }

    Array.from(bell).forEach((element: HTMLElement): void => {
        ReactDOM.render((
            <LanguageProvider>
                <AuthenticationProvider>
                    <NotificationProvider>
                        <Bell/>
                        <Message/>
                    </NotificationProvider>
                </AuthenticationProvider>
            </LanguageProvider>
        ), element);
    });
});