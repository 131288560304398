import { action, observable } from 'mobx';
import { create, IHydrateResult, persist } from 'mobx-persist';

export class AuthenticationController {

    private persistor: (key: string, store: AuthenticationController, initialState?: any) => IHydrateResult<AuthenticationController> = create({storage: localStorage});

    @persist
    @observable
    public token: string = null;

    @persist
    @observable
    public authenticated: boolean = false;

    @action
    public getToken = async (): Promise<string> => {
        await this.persistor('@socia/auth', this);

        return this.token;
    };

}