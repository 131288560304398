import {EventEmitter} from 'events';
import {action, observable} from 'mobx';

import {Influencer} from 'services/user/influencer.model';
import {Label} from 'services/user/label.model';
import {User} from 'services/user/user.model';

import {UserService} from 'services/user/user.service';
import {Member} from 'services/user/member.model';
import {NotificationController} from 'providers/notification/notification.controller';

export class ScreenController extends EventEmitter {

    private readonly userService: UserService = new UserService();

    @observable
    public loading: boolean = false;

    @observable
    public error: Error = null;

    @observable
    public observer: NotificationController = null;

    @observable
    public user: User = null;

    @observable
    public influencers: Member<Influencer>[] = [];

    @observable
    public administrators: Member<User>[] = [];

    @observable
    public labels: Member<Label>[] = [];

    @action
    public load = async (): Promise<void> => {
        try {
            this.error = null;
            this.loading = true;
            this.user = await this.userService.current();
            this.influencers = await this.userService.influencers();
            this.administrators = await this.userService.administrators();
            this.labels = await this.userService.labels();
        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    };

    @action
    public update = async (): Promise<void> => {
        try {
            this.error = null;
            this.influencers = await this.userService.influencers();
            this.administrators = await this.userService.administrators();
            this.labels = await this.userService.labels();
        } catch (error) {
            this.error = error;
        }
    };

    @action
    public subscribe = (observer: NotificationController): void => {
        if (this.observer === null) {
            this.observer = observer;
            observer.on('message', async (): Promise<void> => {
                await this.update();
            });
        }
    };

}