import * as React from 'react';
import { Component, ReactNode } from 'react';
import { observer } from 'mobx-react';

import { ChatController } from 'components/chat/chat.controller';
import { ChatProps } from 'components/chat/chat.props';

import { Error } from 'components/chat/error/error.component';
import { List } from 'components/chat/list/list.component';
import { Room } from 'components/chat/room/room.component';
import { Filter } from 'components/chat/filter/filter.component';
import { Spinner } from 'components/spinner/spinner.component';

import { Member } from 'services/user/member.model';

import { Filter as FilterInterface } from 'interfaces/chat/filter.interface';
import { NotificationController } from 'providers/notification/notification.controller';
import { NotificationConsumer } from 'providers/notification/notification.consumer';

@observer
export class Chat extends Component<ChatProps> {

    public controller: ChatController = new ChatController();

    public componentWillMount(): void {
        this.controller.members = this.props.members;
    }

    public componentWillUpdate(props: ChatProps): void {
        this.controller.members = props.members;
        if (this.controller.activeMember) {
            this.controller.activeMember = props.members.filter((member: Member<any>): boolean => {
                return this.controller.activeMember.type === member.type && this.controller.activeMember.data.id === member.data.id;
            })[0];
            this.controller.activeMember.unread = false;
        }
    }

    public render(): ReactNode {
        return (
            <div className="chat-internal-scope">
                {this.controller.error ? <Error>{this.controller.error.message}</Error> : this.controller.loading ? <Spinner/> : (
                    <div className="chat-conversation-container">
                        <div className="row">
                            {typeof this.props.list === 'boolean' && this.props.list === false ? null : (
                                <div className="col-sm-4">
                                    <List
                                        filter={this.controller.filter}
                                        members={this.controller.members}
                                        activeMember={this.controller.activeMember}
                                        removable={this.props.removable}
                                        onSelect={(member: Member<any>): void => {
                                            this.controller.activeMember = member;
                                        }}
                                        onRemove={async (member: Member<any>): Promise<void> => {
                                            await this.controller.remove(member);
                                        }}
                                    />
                                </div>
                            )}
                            <div className={`${typeof this.props.list === 'boolean' && this.props.list === false ? 'col-sm-12' : 'col-sm-8'}`}>
                                <Filter
                                    value={this.controller.filter}
                                    onChange={(filter: FilterInterface): void => {
                                        this.controller.filter = filter;
                                    }}
                                />
                                <div className="panel-page chat-content-container">
                                    <Room
                                        filter={this.controller.filter}
                                        member={this.controller.activeMember || this.props.activeMember}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

}