import * as React from 'react';
import {Component, createRef, FormEvent, MouseEvent, ReactNode, RefObject} from 'react';
import * as ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Emoji, EmojiData, Picker} from 'emoji-mart';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperclip, faTimes} from '@fortawesome/free-solid-svg-icons';
import {faPaperPlane, faSmile} from '@fortawesome/free-regular-svg-icons';

import {InputProps} from 'components/chat/input/input.props';

@observer
export class Input extends Component<InputProps> {

    public attacher: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
    public input: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
    public picker: RefObject<Picker> = createRef<Picker>();

    public onContentEditableKeyDown = (event: KeyboardEvent): void => {
        switch (event.keyCode || event.which) {
            case 9:
                event.preventDefault();

                let picker: HTMLDivElement = ReactDOM.findDOMNode(this.picker.current) as HTMLDivElement;
                picker.classList.toggle('active');

                break;
            case 13:
                if (!event.ctrlKey) {
                    event.preventDefault();

                    let input: HTMLDivElement = this.input.current as HTMLDivElement;

                    let picker: HTMLDivElement = ReactDOM.findDOMNode(this.picker.current) as HTMLDivElement;
                    picker.classList.remove('active');

                    if (input.innerHTML.trim() !== '' || this.props.attachments.length) {
                        input.innerHTML = '';
                        input.focus();

                        if (typeof this.props.onSend === 'function') {
                            this.props.onSend();
                        }
                    }
                }
                break;
            case 27:
                event.preventDefault();

                let input: HTMLDivElement = this.input.current as HTMLDivElement;
                input.innerHTML = '';

                if (typeof this.props.onChange === 'function') {
                    this.props.onChange('');
                }
                break;
            default:
                break;
        }
    };

    public onContentEditableInput = (event: KeyboardEvent): void => {
        event.preventDefault();
        let input: HTMLDivElement = this.input.current as HTMLDivElement;

        if (typeof this.props.onChange === 'function') {
            this.props.onChange(input.innerHTML.trim());
        }
    };

    public componentDidMount(): void {
        let input: HTMLDivElement = this.input.current as HTMLDivElement;
        let picker: HTMLDivElement = ReactDOM.findDOMNode(this.picker.current) as HTMLDivElement;
        let search: HTMLInputElement = picker.querySelector('.emoji-mart-search > input');

        search.tabIndex = -1;
        input.innerHTML = this.props.message;
        input.addEventListener('keydown', this.onContentEditableKeyDown);
        input.addEventListener('input', this.onContentEditableInput);
        input.addEventListener('textinput', this.onContentEditableInput);
        input.focus();
    }

    public componentWillUnmount(): void {
        let input: HTMLDivElement = this.input.current as HTMLDivElement;
        input.removeEventListener('keydown', this.onContentEditableKeyDown);
        input.removeEventListener('input', this.onContentEditableInput);
        input.removeEventListener('textinput', this.onContentEditableInput);
    }

    public render(): ReactNode {
        return (
            <div className="chat-message-input">
                <Picker
                    ref={this.picker}
                    set="emojione"
                    title="Choose skin color..."
                    emoji=""
                    custom={[]}
                    onSelect={(data: EmojiData): void => {
                        let container: HTMLDivElement = document.createElement('div') as HTMLDivElement;

                        container.innerHTML = Emoji({
                            set: 'emojione',
                            html: true,
                            emoji: data,
                            size: 18,
                            native: true,
                        }) as any;

                        let input: HTMLDivElement = this.input.current as HTMLDivElement;
                        let emoji: HTMLSpanElement = container.children[0] as HTMLSpanElement;

                        input.innerHTML = input.innerHTML += ` ${emoji.innerText}`;

                        let range: Range = document.createRange();
                        let selection: Selection = window.getSelection();
                        range.selectNodeContents(input);
                        range.collapse(false);
                        selection.removeAllRanges();
                        selection.addRange(range);

                        if (typeof this.props.onChange === 'function') {
                            this.props.onChange(input.innerHTML.trim());
                        }
                    }}
                />
                <input
                    ref={this.attacher}
                    type="file"
                    onChange={(event: FormEvent<HTMLInputElement>): void => {
                        event.preventDefault();
                        let input: HTMLInputElement = event.target as HTMLInputElement;
                        let files: File[] = Array.from(input.files);

                        if (typeof this.props.onAttach === 'function') {
                            this.props.onAttach(files);
                        }

                        input.value = '';
                        this.input.current.focus();
                    }}
                />
                {this.props.attachments.length ? (
                    <div className="chat-message-attachments-container">
                        {this.props.attachments.map((file: File, index: number): ReactNode => (
                            <div key={index} className="chat-message-attachment">
                                <div className="chat-message-attachment-icon"><FontAwesomeIcon icon={faPaperclip}/>
                                </div>
                                <div className="chat-message-attachment-name">{file.name}</div>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="chat-message-attachment-remove">Remove</Tooltip>}
                                >
                                    <button
                                        className="chat-message-attachment-remove"
                                        onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                                            event.preventDefault();
                                            if (typeof this.props.onDetach === 'function') {
                                                this.props.onDetach(file);
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        ))}
                    </div>
                ) : null}
                <div className="chat-message-input-container">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="chat-message-input-attachments">Attachments</Tooltip>}
                    >
                        <button
                            tabIndex={-1}
                            className="chat-message-input-control-button"
                            onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                                event.preventDefault();
                                this.attacher.current.click();
                            }}
                        >
                            <FontAwesomeIcon icon={faPaperclip}/>
                        </button>
                    </OverlayTrigger>
                    <div
                        ref={this.input}
                        className="chat-message-input-field"
                        contentEditable
                        placeholder="Type message here..."
                    />
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="chat-message-input-emoji">Emoji</Tooltip>}
                    >
                        <button
                            tabIndex={-1}
                            className="chat-message-input-control-button"
                            onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                                event.preventDefault();
                                let container: HTMLDivElement = ReactDOM.findDOMNode(this.picker.current) as HTMLDivElement;
                                container.classList.toggle('active');
                            }}
                        >
                            <FontAwesomeIcon icon={faSmile}/>
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="chat-message-input-send">Send</Tooltip>}
                    >
                        <button
                            tabIndex={-1}
                            className="chat-message-input-control-button"
                            disabled={!this.props.message.length && !this.props.attachments.length}
                            onClick={async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
                                event.preventDefault();

                                let picker: HTMLDivElement = ReactDOM.findDOMNode(this.picker.current) as HTMLDivElement;
                                picker.classList.remove('active');

                                let input: HTMLDivElement = this.input.current as HTMLDivElement;

                                if (input.innerHTML.trim() !== '' || this.props.attachments.length) {
                                    input.innerHTML = '';

                                    input.focus();

                                    if (typeof this.props.onSend === 'function') {
                                        this.props.onSend();
                                    }
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faPaperPlane}/>
                        </button>
                    </OverlayTrigger>
                </div>
            </div>
        );
    }

}