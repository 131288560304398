import { action, observable } from 'mobx';

import { MessageProps } from 'components/chat/message/message.props';

import { ChatService } from 'services/chat/chat.service';
import { User } from 'services/user/user.model';

export class MessageController {

    private chatService: ChatService = new ChatService();

    @observable
    public loading: boolean = false;

    @observable
    public editing: boolean = false;

    @observable
    public error: Error = null;

    @observable
    public text: string = '';

    @observable
    public attachments: any[] = [];

    @observable
    public sender: User = null;

    @observable
    public createdAt: string = null;

    @observable
    public updatedAt: string = null;

    public constructor(props: MessageProps) {
        this.text = props.text;
        this.attachments = props.attachments;
        this.sender = props.sender;
        this.createdAt = props.created_at || null;
        this.updatedAt = props.updated_at || null;
    }

}