import {AuthService} from 'services/auth/auth.service';
import {Notification} from 'services/notification/notification.model';

export class NotificationService {

    private readonly authService: AuthService = new AuthService();

    public index = async (): Promise<Notification[]> => {
        await this.authService.sync();

        let response: Response = await fetch('/api/notifications', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.authService.token}`
            }
        });

        switch (response.status) {
            case 200:
                let json: { data: Notification[] } = await response.json();
                return json.data;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

    public indexMessages = async (): Promise<Notification[]> => {
        await this.authService.sync();

        let response: Response = await fetch('/api/notifications/messages', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.authService.token}`
            }
        });

        switch (response.status) {
            case 200:
                let json: { data: Notification[] } = await response.json();
                return json.data;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

    public readAll = async (): Promise<Notification[]> => {
        await this.authService.sync();

        let response: Response = await fetch('/api/notifications/readAll', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.authService.token}`
            }
        });

        switch (response.status) {
            case 200:
                let json: { data: Notification[] } = await response.json();
                return json.data;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };


    public readAllMessages = async (): Promise<Notification[]> => {
        await this.authService.sync();

        let response: Response = await fetch('/api/notifications/messages/readAll', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.authService.token}`
            }
        });

        switch (response.status) {
            case 200:
                let json: { data: Notification[] } = await response.json();
                return json.data;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

    public destroy = async (): Promise<void> => {
        await this.authService.sync();

        let response: Response = await fetch('', {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.authService.token}`
            }
        });

        switch (response.status) {
            case 200:
            case 204:
                return;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

}