import { action, observable } from 'mobx';

import { User } from 'services/user/user.model';
import { Member } from 'services/user/member.model';
import { Filter } from 'interfaces/chat/filter.interface';
import { ChatService } from 'services/chat/chat.service';

export class ChatController {

    private readonly chatService: ChatService = new ChatService();

    @observable
    public loading: boolean = false;

    @observable
    public error: Error = null;

    @observable
    public members: Member<any>[] = [];

    @observable
    public activeMember: Member<any> = null;

    @observable
    public filter: Filter = {
        content: '',
        date: null
    };

    @observable
    public remove = async (member: Member<any>): Promise<void> => {
        try {
            await this.chatService.remove(member.chat);

            if (member.type === this.activeMember.type && member.data.id === this.activeMember.data.id) {
                this.activeMember = null;
            }

            this.members = this.members.reduce((members: Member<any>[], current: Member<any>): Member<any>[] => {
                if (current.type === member.type && current.data.id === member.data.id) {
                    members.push({...current, chat: null});
                } else {
                    members.push(current);
                }

                return members;
            }, []);
        } catch (error) {
            return;
        }
    };

}