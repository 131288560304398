import * as React from 'react';
import { Component, ReactNode } from 'react';
import { observer } from 'mobx-react';

import { AuthenticationContext } from 'providers/authentication/authentication.context';
import { AuthenticationController } from 'providers/authentication/authentication.controller';

@observer
export class AuthenticationProvider extends Component {

    public controller: AuthenticationController = new AuthenticationController();

    public render(): ReactNode {
        return <AuthenticationContext.Provider value={this.controller}>{this.props.children}</AuthenticationContext.Provider>;
    }

}