import {action, observable} from 'mobx';

import {NotificationController} from 'providers/notification/notification.controller';

import {NotificationService} from 'services/notification/notification.service';
import {Notification} from 'services/notification/notification.model';

export class BellController {

    private readonly notificationService: NotificationService = new NotificationService();
    private readonly sound: HTMLAudioElement = new Audio('/assets/sound/notification.ogg');

    @observable
    public loading: boolean = false;

    @observable
    public active: boolean = false;

    @observable
    public error: Error = null;

    @observable
    public observer: NotificationController = null;

    @observable
    public notifications: Notification[] = [];

    @action
    public init = async (): Promise<void> => {
        try {
            this.loading = true;
            this.error = null;

            this.notifications = await this.notificationService.index();

            if (this.notifications.length > 0) {
                this.sound.addEventListener('canplaythrough', async () => {
                    await this.sound.play();
                });
            }
        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    };

    @action
    public subscribe = (observer: NotificationController): void => {
        if (this.observer === null) {
            this.observer = observer;
            this.observer.on('notification', async (notification: any) => {
                this.notifications.push(notification);
                this.sound.addEventListener('canplaythrough', async () => {
                    await this.sound.play();
                });
            });
        }
    };

    @action
    public readAll = async (): Promise<void> => {
        try {
            this.notifications = await this.notificationService.readAll();
        } catch (error) {
            this.error = error;
        }
    };

}