import * as React from 'react';
import {Component, FormEvent, MouseEvent, ReactNode} from 'react';
import {observer} from 'mobx-react';
import {Button, ListGroup, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';

import {ListProps} from 'components/chat/list/list.props';
import {ListController} from 'components/chat/list/list.controller';

import {Member} from 'services/user/member.model';

@observer
export class List extends Component<ListProps> {

    public controller: ListController = new ListController();

    public render(): ReactNode {
        return (
            <div className="chat-list">
                <div className="panel-form chat-list-search">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Search in the list..."
                            value={this.controller.query}
                            onChange={async (event: FormEvent<HTMLInputElement>): Promise<void> => {
                                event.preventDefault();
                                let input: HTMLInputElement = event.target as HTMLInputElement;
                                this.controller.query = input.value;
                            }}
                        />
                    </div>
                </div>
                <Modal
                    className="chat-remove-confirm"
                    show={!!this.controller.markedToRemove}
                    onHide={(): void => {
                        this.controller.markedToRemove = null;
                    }}
                >
                    <Modal.Body>
                        Are you sure you want to delete the whole conversation?<br/>
                        This action cannot be undone.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            bsStyle="danger"
                            onClick={(event: MouseEvent<Button>): void => {
                                event.preventDefault();

                                if (typeof this.props.onRemove === 'function') {
                                    this.props.onRemove(this.controller.markedToRemove);
                                }

                                this.controller.markedToRemove = null;
                            }}
                        >
                            YES
                        </Button>
                        <Button
                            bsStyle="default"
                            onClick={(event: MouseEvent<Button>): void => {
                                event.preventDefault();
                                this.controller.markedToRemove = null;
                            }}
                        >
                            NO
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="panel-page">
                    <ListGroup className="chat-list-users">
                        {((): ReactNode => {
                            let members: Member<any>[] = this.controller.filter(this.props.members);

                            if (!members.length) {
                                return (
                                    <div className="chat-list-users-empty">
                                        <span>No chat rooms found.</span>
                                    </div>
                                );
                            }

                            return this.controller.sort(members).map((member: Member<any>, index: number): ReactNode => (
                                <div
                                    key={index}
                                    className={`list-group-item chat-list-user${member === this.props.activeMember ? ' active' : (member.unread) ? ' unread' : ''}`}
                                    onClick={async (event: MouseEvent<HTMLDivElement>): Promise<void> => {
                                        this.props.onSelect(member);
                                    }}
                                >
                                    <div className="chat-list-user-avatar">
                                        <img src={this.controller.image(member)}/>
                                    </div>
                                    <span className="chat-list-user-meta">
                                    <span className="chat-list-user-name">{this.controller.title(member)}</span>
                                    <span className="chat-list-user-email">{this.controller.subtitle(member)}</span>
                                    <span className="chat-list-user-date">{this.controller.date(member)}</span>
                                </span>
                                    {member.chat && (this.props.removable === true) ? (
                                        <span className="chat-list-user-button">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`remove-chat-${member.data.id}`}>Remove this
                                                conversation</Tooltip>}
                                        >
                                            <button
                                                onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                                                    event.preventDefault();
                                                    event.stopPropagation();

                                                    this.controller.markedToRemove = member;
                                                }}
                                            >
                                                <i className="fas fa-trash-alt"/>
                                            </button>
                                        </OverlayTrigger>
                                    </span>
                                    ) : null}
                                </div>
                            ));
                        })()}
                    </ListGroup>
                </div>
            </div>
        );
    }

}