import * as React from 'react';
import { StatelessComponent, MouseEvent, ReactElement } from 'react';

import { ErrorProps } from 'components/chat/error/error.props';

export const Error: StatelessComponent = (props: ErrorProps<HTMLDivElement>): ReactElement<ErrorProps<HTMLDivElement>> => (
    <div className="chat-error-container">
        <div className="chat-error-message">{props.children}</div>
        {typeof props.onReload === 'function' ? (
            <a
                className="chat-error-reload"
                onClick={async (event: MouseEvent<HTMLAnchorElement>): Promise<void> => {
                    event.preventDefault();
                    props.onReload();
                }}
            >
                Try again
            </a>
        ) : null}
    </div>
);
