import { EventEmitter } from 'events';
import { action, computed } from 'mobx';
import { create, persist, IHydrateResult } from 'mobx-persist';

import en from 'i18n/en';
import nl from 'i18n/nl';

import config from 'providers/language/language.config';

import { Locale } from 'interfaces/i18n/locale.interface';
import { Vocabulary } from 'interfaces/i18n/vocabulary.interface';

const persistor: (key: string, store: LanguageController, initialState?: any) => IHydrateResult<LanguageController> = create({
    storage: localStorage
});

export class LanguageController extends EventEmitter {

    @persist
    private _locale: Locale = config.defaultLocale as Locale;

    private readonly vocabulary: { [property in Locale]: Vocabulary } = { en, nl };

    public constructor() {
        super();
        persistor('@@mobx/language', this);
    }

    @action
    public setLocale(locale: Locale): boolean {
        if (config.availableLocales.includes(locale)) {
            this._locale = locale;
            return true;
        }

        return false;
    }

    @computed
    public get locale(): Locale {
        return this._locale;
    }

    @computed
    public get messages(): Vocabulary {
        return this.vocabulary[this._locale];
    }

}