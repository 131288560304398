import { action, observable } from 'mobx';

import { Notification } from 'services/notification/notification.model';
import { NotificationService } from 'services/notification/notification.service';

export class NotificationsController {

    private readonly notificationService: NotificationService = new NotificationService();

    @observable
    public loading: boolean = false;

    @observable
    public error: Error = null;

    @observable
    public notifications: Notification[] = [];

    @action
    public init = async (): Promise<void> => {
        try {
            this.loading = true;
            this.error = null;
            this.notifications = await this.notificationService.index();
        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    };

}