import {EventEmitter} from 'events';
import {action, observable} from 'mobx';

import {User} from 'services/user/user.model';
import {UserService} from 'services/user/user.service';
import {Message} from 'interfaces/chat/message.interface';
import {AuthService} from "services/auth/auth.service";

export class NotificationController extends EventEmitter {

    private readonly userService: UserService = new UserService();
    private readonly authService: AuthService = new AuthService();
    private readonly echo: Echo = new Echo({
        broadcaster: 'socket.io',
        host: `${window.location.hostname}:6001`,
        auth: {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.authService.token}`
            }
        }
    });

    @observable
    public user: User = null;

    @observable
    public channel: any = null;

    @action
    public init = async (): Promise<void> => {
        this.user = await this.userService.current();
        this.channel = this.echo.private(`user.${this.user.id}`);
        this.channel.notification((message: Message) => {
            if (message.type === 'Modules\\Chat\\Notifications\\MessageNotification') {
                this.emit('message', message);
            } else {
                this.emit('notification', message);
            }
        });
    };

    @action
    public destruct = async (): Promise<void> => {
        this.echo.leave(`user.${this.user.id}`);
        this.channel = null;
        this.user = null;
    };

}