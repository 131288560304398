import * as React from 'react';
import {Component, MouseEvent, ReactNode} from 'react';
import {observer} from 'mobx-react';
import {Grid, Nav, NavItem, Panel, Tab} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

import {Chat} from 'components/chat/chat.component';
import {Spinner} from 'components/spinner/spinner.component';

import {ScreenContext} from 'components/screen/screen.context';
import {ScreenController} from 'components/screen/screen.controller';

import {NotificationController} from 'providers/notification/notification.controller';
import {NotificationConsumer} from 'providers/notification/notification.consumer';

import {User} from 'services/user/user.model';
import {RefObject} from "react";
import {createRef} from "react";

@observer
export class Screen extends Component {

    public controller: ScreenController = new ScreenController();

    private defaultActiveKey: string = 'default';

    public async componentWillMount(): Promise<void> {
        await this.controller.load();
    }

    public render(): ReactNode {
        let url = new URL(window.location.href);
        this.defaultActiveKey = url.searchParams.get("chat");
        this.defaultActiveKey = this.defaultActiveKey === 'administration' ? 'administration' : 'default';

        return (
            <div id="chat">
                <ScreenContext.Provider value={this.controller}>
                    <NotificationConsumer>
                        {(observer: NotificationController): ReactNode => {
                            this.controller.subscribe(observer);
                            return null;
                        }}
                    </NotificationConsumer>
                    <Tab.Container id="chat-scope-switcher" defaultActiveKey={this.defaultActiveKey}>
                        <div>
                            <div className="panel-page">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h2>Chat</h2>
                                    </div>
                                </div>
                            </div>
                            {this.controller.error ? (
                                <div className="chat-error-container">
                                    <div className="chat-error-icon"><FontAwesomeIcon icon={faExclamationTriangle}/></div>
                                    <div className="chat-error-message">{this.controller.error.message}</div>
                                    <a
                                        className="chat-error-reload"
                                        onClick={async (event: MouseEvent<HTMLAnchorElement>): Promise<void> => {
                                            event.preventDefault();
                                            await this.controller.update();
                                        }}
                                    >
                                        Try again
                                    </a>
                                </div>
                            ) : this.controller.loading ? (<Spinner/>) : (
                                ((user: User): ReactNode => {
                                    if (user.roles.includes('administrator')) {
                                        return [
                                            <Tab.Content key="content" animation>
                                                <Tab.Pane eventKey="default">
                                                    <Chat
                                                        removable
                                                        members={this.controller.labels}
                                                    />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        ];
                                    } else if (user.roles.includes('label')) {
                                        return [
                                            <Nav key="nav" bsStyle="tabs">
                                                <NavItem eventKey="administration">Chat with administration</NavItem>
                                                <NavItem eventKey="default">Chat with Influencers</NavItem>
                                            </Nav>,
                                            <Tab.Content key="content" animation>
                                                <Tab.Pane eventKey="default">
                                                    <Chat
                                                        removable
                                                        members={this.controller.influencers}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="administration">
                                                    <Chat
                                                        removable
                                                        list={false}
                                                        members={[]}
                                                        activeMember={this.controller.administrators[0]}
                                                    />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        ];
                                    } else if (user.roles.includes('freelancer')) {
                                        return [
                                            <Tab.Content key="content" animation>
                                                <Tab.Pane eventKey="default">
                                                    <Chat members={this.controller.influencers}/>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        ];
                                    }
                                })(this.controller.user)
                            )}
                        </div>
                    </Tab.Container>
                </ScreenContext.Provider>
            </div>
        );
    }

}