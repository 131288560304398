import * as React from 'react';
import { Component, ReactNode } from 'react';
import { observer } from 'mobx-react';

import { NotificationContext } from 'providers/notification/notification.context';
import { NotificationController } from 'providers/notification/notification.controller';

@observer
export class NotificationProvider extends Component {

    public controller: NotificationController = new NotificationController();

    public componentWillMount = async (): Promise<void> => {
        await this.controller.init();
    };

    public componentWillUnmount = async (): Promise<void> => {
        await this.controller.destruct();
    };

    public render(): ReactNode {
        return <NotificationContext.Provider value={this.controller}>{this.props.children}</NotificationContext.Provider>;
    }

}