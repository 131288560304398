import * as React from 'react';
import { createRef, Component, FormEvent, KeyboardEvent, MouseEvent, ReactNode, RefObject } from 'react';
import DateTime = require('react-datetime');
import { Moment } from 'moment';

import { FilterProps } from 'components/chat/filter/filter.props';

export class Filter extends Component<FilterProps> {

    public dateInput: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();

    // @ts-ignore
    public onSearchShortcut = (event: window.KeyboardEvent): void => {
        if ((event.ctrlKey || event.metaKey) && (event.which || event.keyCode) === 70) {
            event.preventDefault();
            this.dateInput.current.focus();
        }
    };

    public componentDidMount = (): void => {
        window.addEventListener('keydown', this.onSearchShortcut);
    };

    public componentWillUnmount = (): void => {
        window.removeEventListener('keydown', this.onSearchShortcut);
    };

    public render(): ReactNode {
        return (
            <div className="chat-filters panel-form">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search on content..."
                                value={this.props.value.content}
                                onKeyDown={(event: KeyboardEvent): void => {
                                    // 27 - "Escape" key code
                                    if ([27].includes(event.which || event.keyCode)) {
                                        event.preventDefault();

                                        if (typeof this.props.onChange === 'function') {
                                            this.props.onChange({...this.props.value, content: ''});
                                        }
                                    }
                                }}
                                onChange={(event: FormEvent<HTMLInputElement>): void => {
                                    event.preventDefault();
                                    let input: HTMLInputElement = event.target as HTMLInputElement;

                                    if (typeof this.props.onChange === 'function') {
                                        this.props.onChange({...this.props.value, content: input.value});
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <DateTime
                                dateFormat="DD / MMM / YY -"
                                timeFormat="HH:mm"
                                value={this.props.value.date ? this.props.value.date.toDate() : null}
                                onChange={(date: Moment): void => {
                                    this.dateInput.current.focus();

                                    if (typeof this.props.onChange === 'function') {
                                        this.props.onChange({...this.props.value, date});
                                    }
                                }}
                                inputProps={{
                                    ref: this.dateInput,
                                    placeholder: 'Search since date...',
                                    readOnly: true,
                                    onKeyDown: (event: KeyboardEvent): void => {
                                        event.preventDefault();
                                        // 8 - "Backspace" key code
                                        // 27 - "Escape" key code
                                        if ([8, 27].includes(event.which || event.keyCode)) {
                                            if (typeof this.props.onChange === 'function') {
                                                this.props.onChange({...this.props.value, date: null});
                                            }
                                        }
                                    }
                                }}
                                isValidDate={(current: Moment): boolean => {
                                    return current.isBefore(new Date().getTime());
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="chat-filters-clear hide">
                    <button
                        onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                            event.preventDefault();

                            if (typeof this.props.onChange === 'function') {
                                this.props.onChange({content: '', date: null});
                            }
                        }}
                    >
                        Clear filters
                    </button>
                </div>
            </div>
        );
    }

}