import * as React from 'react';
import {Component, createRef, KeyboardEvent, MouseEvent, ReactNode, RefObject} from 'react';
import {observer} from 'mobx-react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperclip, faPencilAlt, faTrash} from '@fortawesome/free-solid-svg-icons';

import {MessageController} from 'components/chat/message/message.controller';
import {MessageProps} from 'components/chat/message/message.props';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Spinner} from 'components/spinner/spinner.component';
import dateformat = require('dateformat');

@observer
export class Message extends Component<MessageProps> {

    public input: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
    public controller: MessageController = new MessageController(this.props);

    public render(): ReactNode {
        return (
            <div
                className={`chat-message-container${this.props.sending ? ' sending' : ''}${this.props.own ? ' own-message' : ''}${typeof(this.props.attachments) !== 'undefined' && this.props.attachments.length ? ' files' : ''}`}>
                <div className="chat-message-avatar">
                    <img
                         src={this.props.sender && this.props.sender.avatar_path ? `/uploads/avatars/${this.props.sender.avatar_path}` : '/assets/img/avatar.jpeg'}/>
                </div>
                <div className="chat-message-wrapper">
                    <div className="chat-message-block">
                        <div className={`chat-message-bubble${this.props.text.length ? ' text' : ''}`}>
                            {this.props.text.length  ? (
                                <div
                                    ref={this.input}
                                    className="chat-message-text"
                                    contentEditable={this.controller.editing}
                                    dangerouslySetInnerHTML={{__html: this.props.text}}
                                    onKeyDown={(event: KeyboardEvent<HTMLDivElement>): void => {
                                        switch (event.which || event.keyCode) {
                                            case 13:
                                                event.preventDefault();

                                                let text: string = this.input.current.innerHTML;
                                                if (text !== this.props.text) {
                                                    if (typeof this.props.onEdit === 'function') {
                                                        this.props.onEdit(text);
                                                    }
                                                }
                                                this.controller.editing = false;
                                                break;
                                            case 27:
                                                event.preventDefault();

                                                this.controller.editing = false;
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                />
                            ) : null}
                            <div className={`chat-message-information${this.props.error ? ' has-error' : ''}`}>
                                {this.props.error ? null : (
                                    this.props.sending ? <Spinner/> : (
                                        this.props.own ? (
                                            <div className="chat-message-controls">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id={`chat-message-${this.props.id}-edit`}>Edit</Tooltip>}
                                                >
                                                    <button className="btn btn-brand-default btn-sm"
                                                            onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                                                                event.preventDefault();
                                                                this.controller.editing = true;
                                                                this.input.current.focus();
                                                            }}
                                                    >
                                                        <i className="fas fa-pencil"/>
                                                    </button>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip
                                                        id={`chat-message-${this.props.id}-remove`}>Remove</Tooltip>}
                                                >
                                                    <button className="btn btn-brand-danger btn-sm"
                                                            onClick={async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
                                                                event.preventDefault();
                                                                if (typeof this.props.onDelete === 'function') {
                                                                    this.props.onDelete();
                                                                }
                                                            }}
                                                    >
                                                        <i className="fas fa-trash-alt"/>
                                                    </button>
                                                </OverlayTrigger>
                                            </div>
                                        ) : null
                                    )
                                )}
                            </div>
                        </div>
                        {typeof(this.props.attachments) !== 'undefined' && this.props.attachments.length ? (
                            <div className="chat-message-attachments">
                                <div className="chat-message-attachments-title">Attached
                                    file{this.props.attachments.length > 1 ? 's' : ''}:
                                </div>
                                {this.props.attachments.map((attachment: { name: string; filename: string; url: string }, index: number): ReactNode => (
                                    <div key={index} className="chat-message-attachment">
                                        <div className="chat-message-attachment-icon"><FontAwesomeIcon icon={faPaperclip}/>
                                        </div>
                                        {this.props.error ? (
                                            <span>{attachment.name || attachment.filename}</span>
                                        ) : (
                                            <a
                                                target={this.props.sending ? '_self' : '_blank'}
                                                href={attachment.url || null}
                                                className="chat-message-attachment-name"
                                            >
                                                {attachment.name || attachment.filename}
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : null}
                        <div className="chat-message-footer">
                            <div className="chat-message-meta"
                                 dangerouslySetInnerHTML={{ __html: this.props.error ? this.props.error.message : (
                                         this.props.sending ? 'Sending....' : (
                                             this.props.updated_at === this.props.created_at ? (
                                                 `Sent by <span class="user">${this.props.sender && this.props.sender.name || 'DELETED'}</span> at ${dateformat(new Date(this.props.created_at), 'dd / mmm / yy - HH:MM')}`
                                             ) : (
                                                 `Updated by <span class="user">${this.props.sender && this.props.sender.name || 'DELETED'}</span> at ${dateformat(new Date(this.props.updated_at), 'dd / mmm / yy - HH:MM')}`
                                             )
                                         )
                                     ) }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}