import {action, observable} from 'mobx';

import {AuthService} from 'services/auth/auth.service';
import {ChatService} from 'services/chat/chat.service';
import {UserService} from 'services/user/user.service';

import {Chat} from 'services/chat/chat.model';
import {Member} from 'services/user/member.model';
import {User} from 'services/user/user.model';

import {Message} from 'interfaces/chat/message.interface';
import {NotificationController} from 'providers/notification/notification.controller';

export class RoomController {

    private authService: AuthService = new AuthService();
    private chatService: ChatService = new ChatService();
    private userService: UserService = new UserService();

    @observable
    public loading: boolean = false;

    @observable
    public dragging: boolean = false;

    @observable
    public error: Error = null;

    @observable
    public observer: NotificationController = null;

    @observable
    public user: User = null;

    @observable
    public member: Member<any> = null;

    @observable
    public chat: Chat = null;

    @observable
    public channel: any = null;

    @observable
    public search: string = '';

    @observable
    public history: Message[] = [];

    @observable
    public message: string = '';

    @observable
    public attachments: File[] = [];

    @action
    public init = async (): Promise<void> => {
        try {
            this.loading = true;
            this.error = null;

            await this.authService.sync();
            this.user = await this.userService.current();
        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    };

    @action
    public subscribe = (observer: NotificationController): void => {
        if (this.observer === null) {
            this.observer = observer;
            observer.on('message', async (notification: any): Promise<void> => {
                if (notification.type === 'Modules\\Chat\\Notifications\\DestroyChatNotification') {
                    await this.reload(notification.chat_id);
                }
            });
        }
    };

    @action
    public open = async (member: Member<any>): Promise<void> => {
        try {
            if (!member) {
                return;
            }

            this.loading = true;
            this.error = null;

            this.member = member;

            if (this.chat) {
                await this.chatService.leave(this.chat.id);
                this.chat = null;
                this.channel = null;
            }

            this.chat = (this.user.roles.includes('freelancer')) ? member.chat : await this.chatService.create(member);

            this.history = await this.chatService.messages(this.chat.id);
            this.channel = await this.chatService.join(this.chat.id);

            member.unread = false;

            this.channel.listenForWhisper('message', (message: Message) => {
                this.history = [...this.history, message];
            });

            this.channel.listenForWhisper('update', (message: Message) => {
                this.history = this.history.reduce((history: Message[], current: Message): Message[] => {
                    if (current.id === message.id) {
                        history.push(message);
                    } else {
                        history.push(current);
                    }

                    return history;
                }, []);
            });

            this.channel.listenForWhisper('destroy', (message: Message) => {
                this.history = this.history.filter((item: Message): boolean => {
                    return message.id !== item.id;
                });
            });

        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    };

    @action
    public send = async (): Promise<void> => {
        try {
            // Check if message is empty
            if (!this.message.length && !this.attachments.length) {
                return;
            }

            // Check for limitation of assets size
            let assetsSize: number = 0;

            this.attachments.forEach((file: File): void => {
                assetsSize += file.size;
            });

            // Greater than 100MB
            if (assetsSize > 1e+8) {
                this.history = [...this.history, {
                    id: null,
                    text: this.message,
                    attachments: this.attachments,
                    sender: this.user,
                    sending: false,
                    error: new Error('Attachments total size is larger that 100MB')
                }];

                return;
            }

            this.history = [...this.history, {
                id: null,
                text: this.message,
                attachments: this.attachments,
                sender: this.user,
                sending: true
            }];

            let message: Message = await this.chatService.store(this.chat.id, {
                text: this.message,
                attachments: this.attachments
            });

            this.channel.whisper('message', message);

            this.history.pop();
            this.history = [...this.history, message];
        } catch (error) {
            let last: Message = this.history.pop();
            last.sending = false;
            last.error = error;
            this.history = [...this.history, last];
        } finally {
            this.message = '';
            this.attachments = [];
        }
    };

    @action
    public update = async (message: Message): Promise<void> => {
        if (!this.history.filter((item: Message): boolean => message.id === item.id)[0]) {
            return;
        }

        this.history = this.history.reduce((history: Message[], current: Message): Message[] => {
            if (current.id === message.id) {
                current.sending = true;
            }

            history.push(current);

            return history;
        }, []);

        try {
            let updated: Message = await this.chatService.update(this.chat.id, message);

            this.channel.whisper('update', updated);
            this.history = this.history.reduce((history: Message[], current: Message): Message[] => {
                if (current.id === updated.id) {
                    history.push(updated);
                } else {
                    history.push(current);
                }

                return history;
            }, []);
        } catch (error) {
            this.history = this.history.reduce((history: Message[], current: Message): Message[] => {
                if (current.id === message.id) {
                    current.sending = false;
                    current.error = error;
                }

                history.push(current);

                return history;
            }, []);

            setTimeout(() => {
                this.history = this.history.reduce((history: Message[], current: Message): Message[] => {
                    if (current.id === message.id) {
                        current.error = null;
                    }

                    history.push(current);

                    return history;
                }, []);
            }, 2000);
        }
    };

    @action
    public destroy = async (message: Message): Promise<void> => {
        if (!this.history.filter((item: Message): boolean => message.id === item.id)[0]) {
            return;
        }

        this.history = this.history.reduce((history: Message[], current: Message): Message[] => {
            if (current.id === message.id) {
                current.sending = true;
            }

            history.push(current);

            return history;
        }, []);

        try {
            await this.chatService.destroy(this.chat.id, message);

            this.channel.whisper('destroy', message);
            this.history = this.history.filter((item: Message): boolean => {
                return message.id !== item.id;
            });
        } catch (error) {
            this.history = this.history.reduce((history: Message[], current: Message): Message[] => {
                if (current.id === message.id) {
                    current.sending = false;
                    current.error = error;
                }

                history.push(current);

                return history;
            }, []);

            setTimeout(() => {
                this.history = this.history.reduce((history: Message[], current: Message): Message[] => {
                    if (current.id === message.id) {
                        current.error = null;
                    }

                    history.push(current);

                    return history;
                }, []);
            }, 2000);
        }
    };

    @action
    public reload = async (chatID: number): Promise<void> => {
        if (this.chat && this.chat.id === chatID) {
            await this.chatService.leave(this.chat.id);
            this.channel = null;
            this.chat = null;

            await this.open(this.member);
        }
    };

}