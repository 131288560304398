import { action, observable } from 'mobx';
import { create, persist, IHydrateResult } from 'mobx-persist';

let persistor: (key: string, store: AuthService, initialState?: any) => IHydrateResult<AuthService> = create({storage: localStorage});

export class AuthService {

    @persist
    @observable
    public token: string = null;

    @persist
    @observable
    public authenticated: boolean = false;

    @action
    public sync = async (): Promise<void> => {
        await persistor('@socia/auth', this);
    }

}