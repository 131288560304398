import {Influencer} from 'services/user/influencer.model';
import {Label} from 'services/user/label.model';
import {User} from 'services/user/user.model';

import {AuthService} from 'services/auth/auth.service';
import {Member} from 'services/user/member.model';

export class UserService {

    private readonly authService: AuthService = new AuthService();
    private readonly echo: Echo = new Echo({
        broadcaster: 'socket.io',
        host: `${window.location.hostname}:6001`,
        auth: {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.authService.token}`
            }
        }
    });

    public current = async (): Promise<User> => {
        await this.authService.sync();

        let response: Response = await fetch('/api/users/current', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `bearer ${this.authService.token}`
            }
        });

        switch (response.status) {
            case 200:
                let json: { data: User } = await response.json();
                return json.data;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

    public influencers = async (): Promise<Member<Influencer>[]> => {
        await this.authService.sync();

        let response: Response = await fetch('/api/chat/members/influencers', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `bearer ${this.authService.token}`
            }
        });

        switch (response.status) {
            case 200:
                let json: { data: Member<Influencer>[] } = await response.json();
                return json.data;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

    public administrators = async (): Promise<Member<User>[]> => {
        await this.authService.sync();

        let response: Response = await fetch('/api/chat/members/administrators', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `bearer ${this.authService.token}`
            }
        });

        switch (response.status) {
            case 200:
                let json: { data: Member<User>[] } = await response.json();
                return json.data;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

    public labels = async (): Promise<Member<Label>[]> => {
        await this.authService.sync();

        let response: Response = await fetch('/api/chat/members/labels', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `bearer ${this.authService.token}`
            }
        });

        switch (response.status) {
            case 200:
                let json: { data: Member<Label>[] } = await response.json();
                return json.data;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

    public join = async (id: number): Promise<any> => {
        return new Promise<any>((resolve: (channel: any) => void, reject: (error: Error) => void): void => {
            try {
                resolve(this.echo.private(`user.${id}`));
            } catch (error) {
                reject(error);
            }
        });
    };

    public leave = async (id: number): Promise<any> => {
        return new Promise<any>((resolve: (channel: any) => void, reject: (error: Error) => void): void => {
            try {
                resolve(this.echo.leave(`user.${id}`));
            } catch (error) {
                reject(error);
            }
        });
    };

}