import * as React from 'react';
import { createRef, Component, ReactNode, RefObject } from 'react';
import { Moment } from 'moment';

import { ContentProps } from 'components/chat/content/content.props';
import { Message } from 'components/chat/message/message.component';

import { Message as MessageInterface } from 'interfaces/chat/message.interface';

export class Content extends Component<ContentProps> {

    public container: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    public componentDidMount(): void {
        this.container.current.scrollTop = this.container.current.scrollHeight;
    }

    public componentDidUpdate(prevProps: ContentProps): void {
        if (this.props.messages.length !== prevProps.messages.length) {
            this.container.current.scrollTop = this.container.current.scrollHeight;
        }
    }

    public render(): ReactNode {
        return (
            <div
                ref={this.container}
                className="chat-message-content"
            >
                {this.props.messages.filter((message: MessageInterface): boolean => {
                    let filter: string = this.props.filter.content.toLowerCase();

                    return message.text.toLowerCase().includes(filter);
                }).filter((message: MessageInterface): boolean => {
                    let date: Moment = this.props.filter.date;

                    if (!date) {
                        return true;
                    }

                    let sentAt: Date = message.updated_at === message.created_at ? new Date(message.created_at) : new Date(message.updated_at);

                    return sentAt > date.toDate();
                }).reduce((groups: MessageInterface[][], current: MessageInterface): MessageInterface[][] => {
                    if (groups.length === 0) {
                        groups.push([current]);
                        return groups;
                    }

                    let lastGroup: MessageInterface[] = groups[groups.length - 1];
                    let lastMessage: MessageInterface = lastGroup[lastGroup.length - 1];

                    if (current.sender === null || lastMessage.sender === null) {
                        groups.push([current]);
                    } else if (lastMessage.sender.id === current.sender.id) {
                        lastGroup.push(current);
                    } else {
                        groups.push([current]);
                    }

                    return groups;
                }, []).map((group: MessageInterface[], index: number): ReactNode => (
                    <div className="chat-message-group" key={index}>
                        {group.map((message: MessageInterface): ReactNode => (
                            <Message
                                key={message.id}
                                own={message.sender && this.props.user.id === message.sender.id}
                                {...message}
                                onEdit={(text: string): void => {
                                    if (typeof this.props.onEdit === 'function') {
                                        this.props.onEdit({...message, text});
                                    }
                                }}
                                onDelete={(): void => {
                                    if (typeof this.props.onDelete === 'function') {
                                        this.props.onDelete(message);
                                    }
                                }}
                            />
                        ))}
                    </div>
                ))}
            </div>
        );
    }

}
