import dateformat = require('dateformat');
import { action, observable } from 'mobx';

import { Member } from 'services/user/member.model';
import { Label } from 'services/user/label.model';
import { Influencer } from 'services/user/influencer.model';
import { User } from 'services/user/user.model';
import { Chat } from 'services/chat/chat.model';

export class ListController {

    @observable
    public query: string = '';

    @observable
    public markedToRemove: Member<any> = null;

    @observable
    public removing: boolean = false;

    @action
    public filter(members: Member<any>[]): Member<any>[] {
        return members.filter((member: Member<any>): boolean => {
            let query: string = this.query.toLowerCase();

            switch (member.type) {
                case 'label':
                    let label: Label = member.data as Label;
                    return label.title.toLowerCase().includes(query) || label.contact_person.toLowerCase().includes(query);
                case 'influencer':
                    let influencer: Influencer = member.data as Influencer;
                    return influencer.name.toLowerCase().includes(query);
                case 'user':
                    let user: User = member.data as User;
                    return user.name.toLowerCase().includes(query) || user.email.toLowerCase().includes(query);
                default:
                    return false;
            }
        });
    }

    @action
    public image(member: Member<any>): string {
        switch (member.type) {
            case 'label':
                let label: Label = member.data as Label;
                return label.user.avatar_path ? `/uploads/avatars/${label.user.avatar_path}` : '/assets/img/avatar.jpeg';
            case 'influencer':
                let influencer: Influencer = member.data as Influencer;
                return influencer.user.avatar_path ? `/uploads/avatars/${influencer.user.avatar_path}` : '/assets/img/avatar.jpeg';
            case 'user':
                let user: User = member.data as User;
                return user.avatar_path ? `/uploads/avatars/${user.avatar_path}` : '/assets/img/avatar.jpeg';
            default:
                return '/assets/img/avatar.jpeg';
        }
    }

    @action
    public title(member: Member<any>): string {
        switch (member.type) {
            case 'label':
                let label: Label = member.data as Label;
                return label.title;
            case 'influencer':
                let influencer: Influencer = member.data as Influencer;
                return influencer.name;
            case 'user':
                let user: User = member.data as User;
                return user.name;
            default:
                return 'Undefined';
        }
    }

    @action
    public subtitle(member: Member<any>): string {
        switch (member.type) {
            case 'label':
                let label: Label = member.data as Label;
                return label.contact_person;
            case 'influencer':
                return null;
            case 'user':
                let user: User = member.data as User;
                return user.email;
            default:
                return 'Undefined';
        }
    }

    @action
    public date(member: Member<any>): string {
        if (!member.chat || member.chat.created_at === member.chat.updated_at) {
            return null;
        }

        return dateformat(new Date(member.chat.updated_at), 'dd / mmm / yy - HH:MM');
    }

    @action
    public sort(members: Member<any>[]): Member<any>[] {
        let list: Member<any>[] = [...members];

        list.sort((member1: Member<any>, member2: Member<any>): number => {
            let chat1: Chat = member1.chat;
            let chat2: Chat = member2.chat;

            if (chat1 === null && chat2 === null) {
                return 0;
            }

            if (chat1 === null && chat2 !== null) {
                return 1;
            }

            if (chat1 !== null && chat2 === null) {
                return -1;
            }

            if (chat1.created_at === chat1.updated_at && chat2.created_at === chat2.updated_at) {
                return 0;
            }

            if (chat1.created_at === chat1.updated_at && chat2.created_at !== chat2.updated_at) {
                return 1;
            }

            if (chat1.created_at !== chat1.updated_at && chat2.created_at === chat2.updated_at) {
                return -1;
            }

            let date1: Date = new Date(chat1.updated_at);
            let date2: Date = new Date(chat2.updated_at);

            return date2.getTime() - date1.getTime();
        });

        return list;
    }

}