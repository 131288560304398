import * as React from 'react';
import { ReactElement, StatelessComponent } from 'react';

export const Wave: StatelessComponent = (): ReactElement<{}> => (
    <div className="sk-wave">
        <div className="sk-rect sk-rect1"/>
        <div className="sk-rect sk-rect2"/>
        <div className="sk-rect sk-rect3"/>
        <div className="sk-rect sk-rect4"/>
        <div className="sk-rect sk-rect5"/>
    </div>
);
