import * as React from 'react';
import { Component, ConsumerProps, ReactNode } from 'react';

import { NotificationContext } from 'providers/notification/notification.context';
import { NotificationController } from 'providers/notification/notification.controller';

export class NotificationConsumer extends Component<ConsumerProps<NotificationController>> {

    public render(): ReactNode {
        return <NotificationContext.Consumer>{this.props.children}</NotificationContext.Consumer>;
    }

}