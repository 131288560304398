import * as querystring from 'query-string';

import {AuthService} from 'services/auth/auth.service';
import {Chat} from 'services/chat/chat.model';
import {Member} from 'services/user/member.model';
import {Message} from 'interfaces/chat/message.interface';

export class ChatService {

    private authService: AuthService = new AuthService();

    private echo: Echo = new Echo({
        broadcaster: 'socket.io',
        host: `${window.location.hostname}:6001`,
        auth: {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.authService.token}`
            }
        }
    });

    public create = async (member: Member<any>): Promise<Chat> => {
        await this.authService.sync();

        let response: Response = await fetch('/api/chat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `bearer ${this.authService.token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: querystring.stringify({
                receiver_type: member.type,
                receiver_id: member.data.id
            })
        });

        switch (response.status) {
            case 200:
            case 201:
                let json: { data: Chat } = await response.json();
                return json.data;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

    public remove = async (chat: Chat): Promise<void> => {
        await this.authService.sync();

        let response: Response = await fetch(`/api/chat/${chat.id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `bearer ${this.authService.token}`
            }
        });

        switch (response.status) {
            case 200:
            case 204:
                return;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

    public join = async (id: number): Promise<any> => {
        this.echo = new Echo({
            broadcaster: 'socket.io',
            host: `${window.location.hostname}:6001`,
            auth: {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${this.authService.token}`
                }
            }
        });

        return new Promise<any>((resolve: (channel: any) => void, reject: (error: Error) => void): void => {
            try {
                resolve(this.echo.private(`chat.${id}`));
            } catch (error) {
                reject(error);
            }
        });
    };

    public leave = async (id: number): Promise<any> => {
        return new Promise<any>((resolve: (channel: any) => void, reject: (error: Error) => void): void => {
            try {
                resolve(this.echo.leave(`chat.${id}`));
            } catch (error) {
                reject(error);
            }
        });
    };

    public messages = async (chatId: number): Promise<any> => {
        await this.authService.sync();

        let response: Response = await fetch(`/api/chat/${chatId}/messages`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `bearer ${this.authService.token}`
            }
        });

        switch (response.status) {
            case 200:
            case 201:
                let json: any = await response.json();
                return json.data;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

    public store = async (chatId: number, message: { text: string; attachments: File[] }): Promise<Message> => {
        await this.authService.sync();

        return new Promise<Message>((resolve: any, reject: any): void => {
            let data: FormData = new FormData();
            let xhr: XMLHttpRequest = new XMLHttpRequest();

            if (typeof message.text === 'string' && message.text.length) {
                data.append('text', message.text);
            }

            if (message.attachments.length) {
                message.attachments.forEach((file: File, index: number): void => {
                    if (file instanceof File) {
                        data.append(`attachments[${index}]`, file);
                    }
                });
            }

            xhr.open('POST', `/api/chat/${chatId}/messages`, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', `bearer ${this.authService.token}`);
            xhr.addEventListener('load', () => {
                switch (xhr.status) {
                    case 200:
                    case 201:
                        try {
                            let json: { data: Message } = JSON.parse(xhr.responseText);
                            return resolve(json.data);
                        } catch (error) {
                            return reject(error);
                        }
                    default:
                        return reject(new Error(`Unexpected status code: ${xhr.status}`));
                }
            });

            xhr.addEventListener('error', (event: ErrorEvent) => {
                reject(event.error);
            });

            xhr.send(data);
        });
    };

    public update = async (chatId: number, message: Message): Promise<Message> => {
        await this.authService.sync();

        let response: Response = await fetch(`/api/chat/${chatId}/messages/${message.id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Authorization': `bearer ${this.authService.token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: querystring.stringify({text: message.text})
        });

        switch (response.status) {
            case 200:
            case 201:
                let json: { data: Message } = await response.json();
                return json.data;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

    public destroy = async (chatId: number, message: Message): Promise<void> => {
        await this.authService.sync();

        let response: Response = await fetch(`/api/chat/${chatId}/messages/${message.id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `bearer ${this.authService.token}`
            }
        });

        switch (response.status) {
            case 200:
            case 204:
                return;
            default:
                throw new Error(`Unexpected status code: ${response.status}`);
        }
    };

}
