import * as React from 'react';
import { Component, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { IntlProvider } from 'react-intl';

import { LanguageController } from 'providers/language/language.controller';
import config from 'providers/language/language.config';

@observer
export class LanguageProvider extends Component {

    public controller: LanguageController = new LanguageController();

    public render(): ReactNode {
        return (
            <IntlProvider
                locale={this.controller.locale}
                defaultLocale={config.defaultLocale}
                messages={this.controller.messages}
            >
                {this.props.children}
            </IntlProvider>
        );
    }

}