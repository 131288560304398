import * as React from 'react';
import { Component, ReactNode } from 'react';
import { Table } from 'react-bootstrap';

import { Spinner } from 'components/spinner/spinner.component';

import { NotificationsController } from 'components/notifications/notifications.controller';

export class Notifications extends Component {

    public controller: NotificationsController = new NotificationsController();

    public componentWillMount = async (): Promise<void> => {
        await this.controller.init();
    };

    public render(): ReactNode {
        return (
            <div className="content-container">
                <div className="container-fluid container-md-30">
                    {this.controller.loading ? <Spinner/> : (
                        <div className="table-responsive">
                            <Table bsClass="info">

                            </Table>
                        </div>
                    )}
                </div>
            </div>
        );
    }

}